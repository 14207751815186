
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "environment": "production",
  "url": "https://lemonsqueezy.com",
  "domain": "lemonsqueezy.com",
  "nuxt": {
    "buildId": "5664c862-14f2-4b19-b9fe-0c1e643eb59e"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
